@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../styles/variables';

.horizontal-rounded-corner-content {
  @include media-breakpoint-down(xs) {
    border-radius: 0 !important;
    margin-left: $spacer * -1 !important;
    margin-right: $spacer * -1 !important;
  }
}

.horizontal-rounded-corner-content-col {
  min-height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;

  &-top {
    position: relative;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 auto;
  }

  &-backdrop::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
  }

  @include media-breakpoint-down(sm) {
    min-height: 300px;
  }
}
