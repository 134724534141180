@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '../../styles/variables';
@import '~bootstrap/scss/mixins';

.hubspot-form {
  :global {
    label {
      display: block;
      margin: 0;
    }

    .submitted-message {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .hs-form-field {
      margin-bottom: $form-group-margin-bottom;
    }

    .hs-input {
      display: block;
      width: 100%;
      height: $input-height;
      padding: $input-padding-y $input-padding-x;
      font-family: $input-font-family;
      font-weight: $input-font-weight;
      line-height: $input-line-height;
      color: $input-color;
      background-color: $input-bg;
      background-clip: padding-box;
      border: 2px solid transparent;

      // Unstyle the caret on `<select>`s in IE10+.
      &::-ms-expand {
        background-color: transparent;
        border: 0;
      }

      // Remove select outline from select box in FF
      &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $input-color;
      }

      // Placeholder
      &::placeholder {
        color: $input-placeholder-color;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
      }

      // Disabled and read-only inputs
      //
      // HTML5 says that controls under a fieldset > legend:first-child won't be
      // disabled if the fieldset is disabled. Due to implementation difficulty, we
      // don't honor that edge case; we style them as disabled anyway.
      &:disabled,
      &[readonly] {
        background-color: $input-disabled-bg;
        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
      }

      @include font-size($input-font-size);

      // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
      @include border-radius($input-border-radius, 0);
      @include box-shadow($input-box-shadow);
      @include transition($input-transition);

      // Customize the `:focus` state to imitate native WebKit styles.
      @include form-control-focus($ignore-warning: true);
    }

    textarea.hs-input {
      min-height: 150px;
    }

    .hs-button {
      $hover-background: darken($danger, 7.5%);
      $hover-border: darken($danger, 10%);
      $active-border: darken($danger, 12.5%);
      $active-background: darken($danger, 10%);
      $active-border: darken($danger, 12.5%);

      display: inline-block;
      font-family: $btn-font-family;
      font-weight: $btn-font-weight;
      text-align: center;
      text-decoration: if($link-decoration == none, null, none);
      white-space: $btn-white-space;
      vertical-align: middle;
      user-select: none;
      border: $btn-border-width solid $danger;
      background-color: $danger;
      color: color-yiq($danger);

      &:focus,
      &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
        color: color-yiq($hover-background);
        border-color: $hover-border;

        @include gradient-bg($hover-background);

        @if $enable-shadows {
          @include box-shadow(
            $btn-box-shadow,
            0 0 0 $btn-focus-width
            rgba(mix(color-yiq($danger), $danger, 15%), 0.5)
          );
        }

        @else {
          // Avoid using mixin so we can pass custom focus shadow properly
          box-shadow:
            0
            0
            0
            $btn-focus-width
            rgba(mix(color-yiq($danger), $danger, 15%), 0.5);
        }
      }

      // Disabled comes first so active can properly restyle
      &.disabled,
      &:disabled {
        opacity: $btn-disabled-opacity;
        color: color-yiq($danger);
        background-color: $danger;
        border-color: $danger;

        @include box-shadow(none);
        // Remove CSS gradients if they're enabled
        @if $enable-gradients {
          background-image: none;
        }
      }

      &:not(:disabled):not(.disabled) {
        cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

        &:active,
        &.active {
          color: color-yiq($active-background);
          background-color: $active-background;
          border-color: $active-border;

          &:focus {
            @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);

            @if $enable-shadows and $btn-active-box-shadow != none {
              @include box-shadow(
                $btn-active-box-shadow,
                0 0 0 $btn-focus-width
                rgba(mix(color-yiq($danger), $danger, 15%), 0.5)
              );
            }

            @else {
              // Avoid using mixin so we can pass custom focus shadow properly
              box-shadow:
                0
                0
                0
                $btn-focus-width
                rgba(mix(color-yiq($danger), $danger, 15%), 0.5);
            }
          }

          @include box-shadow($btn-active-box-shadow);

          @if $enable-gradients {
            background-image: none; // Remove the gradient for the pressed/active state
          }
        }
      }

      @include button-size(
        $btn-padding-y,
        $btn-padding-x,
        $btn-font-size,
        $btn-line-height,
        $btn-border-radius
      );
      @include transition($btn-transition);
      @include gradient-bg($danger);
      @include box-shadow($btn-box-shadow);

      @include hover() {
        color: color-yiq($hover-background);
        border-color: $hover-border;

        @include gradient-bg($hover-background);
      }
    }
  }

  &.dark-theme {
    :global {
      .submitted-message {
        color: white;
      }

      label {
        color: white;
      }

      .hs-input {
        border-color: white;
        background: transparent;
        border-width: 3px;
        color: white;
        // Placeholder
        &::placeholder {
          color: rgba(255, 255, 255, 0.5);
          // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
          opacity: 1;
        }
      }
    }
  }
}
