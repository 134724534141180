@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.slsf-progressbar {
  color: red;

  :global {
    .react-sweet-progress-symbol {
      display: none;
    }

    .react-sweet-progress-line-inner {
      overflow: hidden;
      background:
        transparent
        linear-gradient(90deg, var(--secondary) 0%, var(--secondary-dark) 100%)
        0% 0% no-repeat padding-box;
      height: 60px;
      position: relative;
      min-width: 60px;

      &::after {
        content: '';
        background: url('/imgs/logo.svg');
        background-size: contain;
        height: 60px;
        width: 60px;
        display: block;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }

  &-sm {
    :global {
      .react-sweet-progress-line-inner {
        height: 40px;
        min-width: 40px;

        &::after {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  &-md {
    :global {
      .react-sweet-progress-line-inner {
        height: 60px;
        min-width: 60px;

        &::after {
          height: 60px;
          width: 60px;
        }
      }
    }
  }

  &-lg {
    :global {
      .react-sweet-progress-line-inner {
        height: 80px;
        min-width: 80px;

        &::after {
          height: 80px;
          width: 80px;
        }
      }
    }
  }
}

.icon {
  position: absolute;
  right: 0;
  bottom: 100%;
}

.progressbar-container {
  margin-top: 3rem;

  @include media-breakpoint-between(sm, md) {
    margin-bottom: 3rem;
  }
}
