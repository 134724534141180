@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.smaller-heading {
  font-size: 1.5rem;
  margin: 1.5rem 0;
}

.custom-donation-label {
  color: #000;
  font-size: 1.2rem;
  font-weight: 600;
}

@include media-breakpoint-down(sm) {
  .donate-label {
    font-size: 1rem;
  }
}

.checkbox-group {
  transition: all 0.5s ease;
  max-height: 80px;
}

.hide {
  max-height: 0;
  overflow: hidden;
}
