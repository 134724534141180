@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.accordian-toggle:focus {
  outline: 0;
  box-shadow: none;
}

.card {
  border: 2px solid var(--light-blue) !important;
  border-radius: 16px;
  box-shadow: none;

  &:not(:last-of-type) {
    border-radius: 1rem !important;
  }

  &:not(:first-of-type) {
    border-radius: 1rem !important;
  }

  @include media-breakpoint-down(sm) {
    box-shadow: none !important;
  }
}
