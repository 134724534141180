@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.hubspot-form-col {
  iframe {
    width: 100% !important;
  }
}

.mobile-phone-image {
  touch-action: none;
  pointer-events: none;
  margin-top: -114px;
  margin-bottom: -160px;
  display: block;
  width: 100%;

  @include media-breakpoint-down(sm) {
    position: absolute;
    margin: auto;
    right: 0;
    top: -30px;
    width: 40vw;
    touch-action: none;
    pointer-events: none;
  }
}

.col-text {
  @include media-breakpoint-down(sm) {
    padding-right: 35vw;
  }

  @media (max-width: 385px) {
    padding-right: 0;

    h2 {
      padding-right: 40vw;
    }
  }
}

.bg-secondary {
  position: relative;
}
