@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '../../styles/variables';

.background {
  background: url('/imgs/404background.png') no-repeat center;
  background-size: cover;
  min-height: 80vh;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(0deg, black, rgba(0, 0, 0, 0));
    background-blend-mode: multiply;
    pointer-events: none;
    touch-action: none;
  }
}

.logo {
  width: 10rem !important;
  height: 10rem !important;
}

.h1 {
  font-size: 8.3rem;
}

.h2 {
  font-size: 2.5rem;
}

.container {
  max-width: 940px;
  width: 100%;
}

.p {
  max-width: 600px;
}

.form {
  max-width: 600px;
  width: 100%;
}

@include media-breakpoint-down(sm) {
  .h1 {
    font-size: 20vw;
  }

  .h2 {
    font-size: 7.5vw;
  }

  .logo {
    width: 24vw !important;
    height: 24vw !important;
  }

  .background::before {
    background: linear-gradient(0deg, black, black 35%, rgba(0, 0, 0, 0));
  }
}
