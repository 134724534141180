@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.root {
  width: 100%;
  position: fixed;
  z-index: 110;
  top: 132px;

  .link {
    text-decoration: underline;
    cursor: pointer;
  }

  @include media-breakpoint-only(lg) {
    top: 167px;
  }

  @include media-breakpoint-only(md) {
    top: 154px;
  }

  @include media-breakpoint-only(sm) {
    top: 102px;
  }

  @include media-breakpoint-only(xs) {
    top: 102px;
  }
}
