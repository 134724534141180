@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.bg-light-grey {
  @include media-breakpoint-down(sm) {
    ul {
      max-height: 270px;
    }
  }

  @include media-breakpoint-down(470px) {
    ul {
      max-height: none;
    }
  }
}
