.slsf-switch {
  color: red;

  :global {
    .react-switch-handle {
      background: url('/imgs/logo.svg') !important;
      background-size: contain !important;
    }
  }
}
