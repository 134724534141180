@keyframes stretch {
  0% {
    opacity: 0;
    width: 0;
  }
}

.search-bar-input {
  height: 44px;
  width: 100%;
  opacity: 1;
  animation: stretch 0.2s ease;
}
