@import 'styles/variables';

.vertical-tile {
  &-image-wrapper {
    height: 200px;
    position: relative;
    overflow: hidden;
    margin: $card-spacer-x * -2 $card-spacer-x * -2 $card-spacer-x * 1.5;
    display: flex;
    flex-direction: column;

    > img {
      display: block;
      flex: 1;
      object-fit: cover;
      object-position: center;
    }

    &-backdrop::before {
      z-index: 2;
      content: '';
      display: block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
      background: rgba(0, 0, 0, 0.2);
    }

    &-top {
      position: absolute;
      z-index: 10;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      > img {
        max-width: 70%;
        display: block;
        max-height: 80%;
        object-fit: contain;
      }
    }
  }
}
