.faq-accordion-list {
}

.card {
  border: 2px solid var(--light-blue) !important;
  border-radius: 16px;
  &:not(:last-of-type) {
    border-radius: 1rem !important;
  }
  &:not(:first-of-type) {
    border-radius: 1rem !important;
  }
}

.accordian-toggle-arrow,
.accordian-toggle-arrow:focus {
  border: 0;
  background: 0;
  outline: 0;
}
.active {
  transform: rotate(180deg);
  transition: transform 300ms ease-in-out;
}
