.root {
  img {
    min-height: 250px;
    transition: transform 0.5s ease;
    will-change: transform;

    &:hover {
      transform: scale(1.1);
    }
  }
}
